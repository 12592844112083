import React from 'react';
import { useNavigate } from 'react-router-dom';
import useGameCategoryStore from '@Store/gameCategoryStore'; // useGameCategoryStore import
import { TGameCategory } from '@Api/types';
import { useLocation } from 'react-router-dom';
import { ROUTE_PATH } from '@Configure/constant';

interface CategoryMenuList {
  name: string;
  id: TGameCategory;
}

const categories: CategoryMenuList[] = [
  {
    name: '취중진담',
    id: 'serious',
  },
  {
    name: '가족과 함께',
    id: 'family',
  },
  {
    name: '가벼운 질문',
    id: 'funny',
  },
];

const Index: React.FC = () => {
  const navigate = useNavigate();
  // get url and includes single or multi
  const { pathname } = useLocation();
  console.log(pathname);
  const setCategory = useGameCategoryStore((state) => state.setCategory); // 카테고리 저장 함수

  const handleSelectCategory = (category: TGameCategory) => {
    setCategory(category); // 카테고리 저장
    if (pathname.includes(ROUTE_PATH.SINGLE_PAGE)) {
      console.log('싱글페이지');
      navigate(`${ROUTE_PATH.SINGLE_PAGE}${ROUTE_PATH.ANSWER_SELECT}`);
    } else if (pathname.includes(ROUTE_PATH.MULTI_PAGE)) {
      console.log('멀티페이지');

      navigate(`${ROUTE_PATH.MULTI_PAGE}`);
    } else {
      console.log('홈페이지');
      navigate(ROUTE_PATH.HOME);
    }
  };

  return (
    <div className='p-4 max-w-sm mx-auto grid grid-cols-3 gap-4'>
      {categories.map((category, index) => (
        <button
          key={index}
          className='flex items-center justify-center rounded-md bg-stone-700 text-white h-24 w-24 text-center text-sm leading-none'
          onClick={() => handleSelectCategory(category.id)}
        >
          {category.name}
        </button>
      ))}
    </div>
  );
};

export default Index;
