import { TGameCategory } from '@Api/types';
import { create } from 'zustand';

interface GameCategoryState {
  category: TGameCategory | null;
  setCategory: (category: TGameCategory) => void;
  resetCategory: () => void;
}

const useGameCategoryStore = create<GameCategoryState>((set) => ({
  category: null,
  setCategory: (category: TGameCategory) => set({ category }),
  resetCategory: () => set({ category: null }),
}));

export default useGameCategoryStore;
